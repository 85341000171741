import { h } from "preact";
import { useState } from "preact/hooks";
import { Link } from "preact-router/match";

import classNames from "classnames";

import Cross from "../cross";

import style from "./style.scss";

const Header = () => {
    const [menuVisible, setMenuVisible] = useState(false);

    const navClassnames = classNames({ [style.visible]: menuVisible });

    const links = [
        { type: "link", text: "Home", href: "/" },
        { type: "link", text: "About", href: "/about" },
        {
            type: "section",
            text: "Projects",
            className: style.projects,
            links: [
                {
                    type: "link",
                    text: "Rita Loves Oranges",
                    href: "/projects/rita_loves_oranges",
                },
                {
                    type: "link",
                    text: "Party Straws",
                    href: "/projects/party_straws",
                },
                {
                    type: "link",
                    text: "Electric Notalgia",
                    href: "/projects/electric_nostalgia",
                },
            ],
        },
        { type: "link", text: "Contact", href: "/contact" },
        // { type: "link", text: "Press", href: "/press" },
    ];

    const crossClassnames = classNames(style.cross, {
        [style.rotated]: menuVisible,
    });

    function linkPressed() {
        setMenuVisible(false);
    }

    return (
        <header class={style.header}>
            <Cross
                class={crossClassnames}
                onClick={() =>
                    setMenuVisible((oldMenuVisible) => !oldMenuVisible)
                }
            />
            <nav class={navClassnames}>
                {links.map((entry) => {
                    if (entry.type == "link") {
                        return (
                            <Link
                                activeClassName={style.active}
                                href={entry.href}
                                onClick={linkPressed}
                            >
                                {entry.text.toUpperCase()}
                            </Link>
                        );
                    }
                    if (entry.type == "section") {
                        const sectionClassnames = classNames(
                            style.section,
                            entry.className
                        );
                        return (
                            <div className={sectionClassnames}>
                                <p className={style.section_title}>
                                    {entry.text.toUpperCase()}
                                </p>
                                {entry.links?.map((sectionEntry) => (
                                    <Link
                                        activeClassName={style.active}
                                        href={sectionEntry.href}
                                        onClick={linkPressed}
                                    >
                                        * {sectionEntry.text.toUpperCase()}
                                    </Link>
                                ))}
                            </div>
                        );
                    }
                })}
            </nav>
        </header>
    );
};

export default Header;
