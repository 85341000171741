import { h } from "preact";

import classNames from "classnames";

import style from "./style.scss";

export default function cross(props: { class?: string; onClick?(): void }) {
    const classnames = classNames(props.class);
    return (
        <div class={classnames} onClick={props.onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.38 6.38">
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <line
                            className={style.line}
                            y1="3.19"
                            x2="6.38"
                            y2="3.19"
                        />
                        <line
                            className={style.line}
                            x1="3.19"
                            y1="6.38"
                            x2="3.19"
                        />
                    </g>
                </g>
            </svg>
        </div>
    );
}
