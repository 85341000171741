import { h } from "preact";
import { Route, Router } from "preact-router";

import Header from "./header";

// Code-splitting is automated for `routes` directory
import Home from "../routes/home";
import Project from "../routes/project";
import Contact from "../routes/contact";

const App = () => (
  <div id="app">
    <Header />
    <Router>
      <Route path="/" component={Home} />
      <Route path="/contact" component={Contact} />
      <Route path="/projects/:id" component={Project} />
      <Route path="/about" component={Project} id="about" />
    </Router>
  </div>
);

export default App;
